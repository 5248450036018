import gql from "graphql-tag";

export const GET_ERR_FORBIDDEN = gql`
   query GetErrForbidden{
      ErrorSimulator{
         Forbidden
      }
   }
`;

export const GET_ERR_INVALID_GQL = gql`
   query GetErrInvalidGql($StringInput: Int!)  {
      ErrorSimulator{
         InvalidGQL(StringInput:$StringInput)
      }
   }
`;
export const GET_ERR_TIMEOUT = gql`
   query GetErrTimeout {
      ErrorSimulator{
         Timeout
      }
   }
`;