import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { Router } from "@reach/router";
import { Layout, PrivateRoute, ContentLoading, queryLoad, ContentContainer } from "../components";
import "react-table/react-table.css";
import { RouteComponentProps } from "@reach/router";
import { Container, Paper, Divider, Button, ButtonGroup } from "@material-ui/core";

import { GET_ERR_FORBIDDEN, GET_ERR_INVALID_GQL, GET_ERR_TIMEOUT } from "../common/models/errorSimulator";
import { GetErrForbidden } from "../common/models/types/GetErrForbidden";
import { GetErrInvalidGql } from "../common/models/types/GetErrInvalidGql";
import { GetErrTimeout } from "../common/models/types/GetErrTimeout";

const ErrorSimulator = () => {
  const [
    getErrForbidden,
    { data:dF, error:eF, loading:lF, called:cF , refetch: rF},
  ] = useLazyQuery<GetErrForbidden>(GET_ERR_FORBIDDEN);

  const [
    getErrInvalidGql,
    { data:dI, error:eI, loading:lI, called:cI , refetch: rI},
  ] = useLazyQuery<GetErrInvalidGql>(GET_ERR_INVALID_GQL);

  const [
    getErrTimeout,
    { data:dT, error:eT, loading:lT, called:cT , refetch: rT },
  ] = useLazyQuery<GetErrTimeout>(GET_ERR_TIMEOUT);

  const [errType, setErrType] = useState(1);

  useEffect(() => {
    if(errType) {
      //console.log("errType", errType);
      switch(errType){
        case 1: getErrForbidden(); break;
        case 2: getErrInvalidGql(); break;
        case 3: getErrTimeout(); break;
      }
    }
  }, [errType]);

  return (
    <ContentContainer>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button onClick={()=> setErrType(1)} key='bt-x-1'>Forbidden</Button>
        <Button onClick={()=> setErrType(2)} key='bt-x-2'>Invalid GQL</Button>
        <Button onClick={()=> setErrType(3)} key='bt-x-3'>Timeout</Button>
      </ButtonGroup>
      <br/><br/>
      { (errType == 1) && queryLoad([!!lF], [eF]) || ("")}
      { errType == 2 && queryLoad([!!lI], [eI]) || ("")}
      { errType == 3 && queryLoad([!!lT], [eT]) || ("")}
    </ContentContainer>
  );
};

export default (a: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute default component={ErrorSimulator} path="/errorsimulator/" pagetitle="LDP Error Simulator"/>
      </Router>
    </Layout>
  );
};